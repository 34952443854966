import { Location } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Role } from '../../../shared/enums/role';
import { AuthService } from '../../../shared/services/auth.service';
import { SharedModule } from '../../../shared/shared.module';
import { ImagePaths } from '../../../shared/enums/image-paths.enum';

@Component({
  selector: 'app-menu-sidebar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './menu-sidebar.component.html',
  styleUrl: './menu-sidebar.component.scss',
})
export class MenuSidebarComponent implements OnInit {
  showMenu: string = '';
  menu_collapsed: boolean = true;
  userRole: string | null = null;
  role: typeof Role = Role;
  imagePath: typeof ImagePaths = ImagePaths;

  #currentRouterLocationService = inject(Location);
  #router = inject(Router);
  #authService = inject(AuthService);

  #destroyRef = inject(DestroyRef);

  navbar_dropdown_items_admin = [
    {
      label: 'Cadastro de Empresa',
      icon: 'icon-svg icon-lamp icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/users/establishment/new']);
      },
    },
    {
      label: 'Cadastro de Serviços',
      icon: 'icon-svg icon-term icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/services/new']);
      },
    },
    {
      label: 'Cadastro de Profissional',
      icon: 'icon-svg icon-person-plus icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/users/professional/new']);
      },
    },
  ];

  navbar_dropdown_my_data = [
    {
      label: 'Meu Perfil',
      icon: 'icon-svg icon-person icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/profile/view']);
      },
    },
    {
      label: 'Dados Bancários',
      icon: 'icon-svg icon-bank icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/bank/register']);
      },
    }
  ];

  navbar_dropdown_my_data_admin = [
    {
      label: 'Meu Perfil',
      icon: 'icon-svg icon-person icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/profile/view']);
      },
    },
    {
      label: 'Dados Bancários',
      icon: 'icon-svg icon-bank icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/bank/register']);
      },
    }
  ];

  navbar_dropdown_items_professional_product = [
    {
      label: 'Cadastrar Produtos',
      icon: 'icon-svg icon-package icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/register/product/new']);
      },
    },
    {
      label: 'Meus Produtos',
      icon: 'icon-svg icon-document icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/register/product/view']);
      },
    },
  ];

  navbar_dropdown_items_professional_service = [
    {
      label: 'Cadastrar Serviços',
      icon: 'icon-svg icon-box icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/register/service/new']);
      },
    },
    {
      label: 'Meus Serviços',
      icon: 'icon-svg icon-document icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/register/service/view']);
      },
    },
  ];

  ngOnInit() {
    this.showMenu = this.#currentRouterLocationService.path();
    this.#currentRouterLocationService.onUrlChange((current_route) => {
      this.showMenu = current_route;
    });
    this.userRole = this.#authService.userSnapshot!.role;
  }

  logout() {
    this.#authService.logout();
  }
}
