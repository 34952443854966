import { Component, inject } from '@angular/core';
import { UserJwtPayload } from '../../../shared/interfaces/user-jwt';
import { AuthService } from '../../../shared/services/auth.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { SharedModule } from '../../../shared/shared.module';
import { FilesService } from '../../../shared/services/files.service';
import { ImagePaths } from '../../../shared/enums/image-paths.enum';

@Component({
  selector: 'app-navbar-top',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './navbar-top.component.html',
  styleUrl: './navbar-top.component.scss',
})
export class NavbarTopComponent {
  user: UserJwtPayload | null = null;
  userPhoto: any = ImagePaths.USER_PHOTO_DEFAULT;
  imagePath: typeof ImagePaths = ImagePaths;

  #authService = inject(AuthService);
  #filesService = inject(FilesService);
  #logger = inject(LoggerService);

  ngOnInit() {
    this.user = this.#authService.userSnapshot;
    this.user!.name = this.user!.name.split(' ')[0];
    this.#logger.info('NavbarTopComponent: ', 'User by token jwt\n', this.user);
    if (!this.user) {
      this.#logger.error(
        'NavbarTopComponent: ',
        'User by token jwt not found\n'
      );
      this.#authService.logout();
    }
    this._getUserPhoto();
  }

  private _getUserPhoto(): void {
    if (this.user?.userFullData?.photo) {
      this.#filesService
        .getImageByHash(this.user?.userFullData?.photo)
        .subscribe({
          next: (data) => {
            const objectUrl = URL.createObjectURL(data);
            this.userPhoto = objectUrl;
            this.#logger.info('NavbarTopComponent: ', 'User photo\n', data);
          },
          error: (error) => {
            this.#logger.error(
              'NavbarTopComponent: ',
              'User photo not found\n',
              error
            );
          },
        });
    }
  }

  private _isBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
}
