import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { LoggerService } from '../../shared/services/logger.service';

export const authTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService = inject(LocalStorageService);
  const logger = inject(LoggerService);
  const authToken = localStorageService.getAuthToken();
  const languageId = localStorageService.getLanguageID();

  if (authToken) {
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
        Organization: environment.organizationId,
        Language: `${languageId}`,
      },
    });
    logger.info('Interceptor Request\n', authReq);
    return next(authReq);
  } else {
    const noAuthReq = req.clone({
      setHeaders: {
        Authorization: ``,
        Organization: environment.organizationId,
        Language: `${languageId}`,
      },
    });
    logger.info('Interceptor Request\n', noAuthReq);
    return next(noAuthReq);
  }
};
