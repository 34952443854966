import { Routes } from '@angular/router';
import { RoleGuard } from './core/guards/role.guard';
import { Role } from './shared/enums/role';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: 'login',
    loadComponent: () =>
      import(
        './features/login/login-professional/login-professional.component'
      ).then((m) => m.LoginProfessionalComponent),
  },
  {
    path: 'acesso',
    loadComponent: () =>
      import('./features/login/login-admin/login-admin.component').then(
        (m) => m.LoginAdminComponent
      ),
  },
  {
    path: 'admin/home',
    loadChildren: () =>
      import('./features/home/home-admin/home-admin.routes').then(
        (m) => m.HOME_ADMIN_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'profile',
    loadChildren: () =>
      import(
        './features/profiles/provider-profile/provider-profile.routes'
      ).then((m) => m.PROVIDER_PROFILE_ROUTES),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN, Role.PROFESSIONAL] },
  },
  {
    path: 'customer-profile',
    loadChildren: () =>
      import(
        './features/profiles/customer-profile/customer-profile.routes'
      ).then((m) => m.CUSTOMER_PROFILE_ROUTES),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN, Role.PROFESSIONAL] },
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('./features/users/users.routes').then((m) => m.USERS_ROUTES),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'admin/pending-approval',
    loadComponent: () =>
      import(
        './features/pending-approval-professional/pending-approval-professional.component'
      ).then((m) => m.PendingApprovalProfessionalComponent),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'admin/services',
    loadChildren: () =>
      import('./features/service-area/service-area.routes').then(
        (m) => m.SERVICE_AREA_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'professional/home',
    loadChildren: () =>
      import('./features/home/home-professional/home-professional.routes').then(
        (m) => m.HOME_PROFESSIONAL_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/service-config',
    loadChildren: () =>
      import('./features/service-config/service-config.routes').then(
        (m) => m.SERVICE_CONFIG_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/map-service',
    loadComponent: () =>
      import('./features/map-service/map-service.component').then(
        (m) => m.MapServiceComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/register',
    loadChildren: () =>
      import('./features/offerings/offerings.routes').then(
        (m) => m.OFFERINGS_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/appointments',
    loadComponent: () =>
      import('./features/appointments/appointments.component').then(
        (m) => m.AppointmentsComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/next-appointments',
    loadComponent: () =>
      import('./features/next-appointments/next-appointments.component').then(
        (m) => m.NextAppointmentsComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/bank',
    loadChildren: () =>
      import(
        './features/banking/bank-professional/bank-professional.routes'
      ).then((m) => m.BANK_PROFESSIONAL_ROUTES),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'professional/calendar',
    loadComponent: () =>
      import('./features/event-calendar/event-calendar.component').then(
        (m) => m.EventCalendarComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL] },
  },
  {
    path: 'user/payment-statement',
    loadComponent: () =>
      import('./features/payment-statement/payment-statement.component').then(
        (m) => m.PaymentStatementComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL, Role.ADMIN] },
  },
  {
    path: 'admin/bank',
    loadChildren: () =>
      import('./features/banking/bank-admin/bank-admin.routes').then(
        (m) => m.BANK_ADMIN_ROUTES
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.ADMIN] },
  },
  {
    path: 'help-center',
    loadComponent: () =>
      import('./features/help-center/help-center.component').then(
        (m) => m.HelpCenterComponent
      ),
    canActivate: [RoleGuard],
    data: { roles: [Role.PROFESSIONAL, Role.ADMIN] },
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
