import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  #http = inject(HttpClient);

  constructor() {}

  getLanguageID(lang: string): Observable<any> {
    const params = new HttpParams()
      .set('country-code', lang.replace('-', '_'))
      .set('principal', false);
    return this.#http.get(
      `${environment.shoppinggooUrl}/global/language/country-code`,
      { params }
    );
  }
}
