<div id="navbar-top" class="w-full fadein animation-duration-2000">
  <nav class="grid">
    <div class="col-5 md:col-8 align-self-center pr-0 md:pr-2">
      <div class="grid my-auto">
        <div class="col-12 md:col-9 py-0" *ngIf="false">
          <p-iconField iconPosition="right">
            <p-inputIcon styleClass="pi pi-search" />
            <input
              type="text"
              pInputText
              placeholder="Busque por profissionais, pacientes, consultas ou outras informações..."
              class="w-full border-0 border-round-md input-search__text"
            />
          </p-iconField>
        </div>
        <div class="hidden md:block md:col-3 py-0" *ngIf="false">
          <p-calendar
            [iconDisplay]="'input'"
            [showIcon]="true"
            inputId="icondisplay"
            styleClass="w-full h-full border-0 border-round-md calendar__style"
          />
        </div>
      </div>
    </div>
    <div class="col-7 md:col-4 pl-0 md:pl-2 flex justify-content-end">
      <div class="inline-flex justify-content-end align-items-center">
        <div class="flex-1 justify-content-center">
          <button pButton pRipple [text]="true" class="p-0">
            <i class="icon-svg icon-message-text icon__navbar-top__button icon__navbar-top--color"></i>
          </button>
        </div>
        <div class="flex-1 justify-content-center md:ml-2">
          <button pButton pRipple [text]="true" class="p-0" routerLink="/professional/calendar">
            <i class="icon-svg icon-note icon__navbar-top__button icon__navbar-top--color"></i>
          </button>
        </div>
        <div class="flex-1 justify-content-center md:ml-2">
          <button pButton pRipple pBadge [text]="true" class="p-0" severity="danger" class="badge__notification">
            <i class="icon-svg icon-notification icon__navbar-top__button" ></i>
          </button>
        </div>
        <div class="flex-1 md:ml-2">
          <div class="flex align-items-center">
            <div class="avatar__border cursor-pointer" routerLink="/profile/view">
              <p-avatar
                [image]="userPhoto"
                (onImageError)="imagePath.USER_PHOTO_DEFAULT"
                styleClass="vertical-align-middle avatar--size__small"
                shape="circle"
              />
            </div>
            <div class="font-semibold text-900 ml-2 white-space-nowrap hidden lg:block">
              <span class="font-normal text-400 text-sm">Olá,</span>
              <br />{{ user?.name | titlecase }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </nav>
</div>
