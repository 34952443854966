import { Component, DestroyRef, inject } from '@angular/core';
import { UserJwtPayload } from '../../../shared/interfaces/user-jwt';
import { AuthService } from '../../../shared/services/auth.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { SharedModule } from '../../../shared/shared.module';
import { FilesService } from '../../../shared/services/files.service';

@Component({
  selector: 'app-navbar-top',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './navbar-top.component.html',
  styleUrl: './navbar-top.component.scss',
})
export class NavbarTopComponent {
  selectedDate: string = 'Hoje';

  datesOptions = [
    { name: 'Hoje' },
    { name: 'Semana' },
    { name: 'Mês' },
    { name: 'Mês anterior' },
    { name: 'Ano' },
  ];
  user: UserJwtPayload | null = null;
  userPhoto: any = 'assets/images/photo_profile_default.png';

  #authService = inject(AuthService);
  #filesService = inject(FilesService);
  #logger = inject(LoggerService);

  ngOnInit() {
    this.user = this.#authService.userSnapshot;
    this.#logger.info('NavbarTopComponent: ', 'User by token jwt\n', this.user);
    if (!this.user) {
      this.#logger.error('NavbarTopComponent: ', 'User by token jwt not found\n');
      this.#authService.logout();
    }
    // this._getUserPhoto();
  }

  private _getUserPhoto() {
    if (this.user?.userFullData?.photo) {
      this.#filesService.getImageByHash(this.user?.userFullData?.photo).subscribe({
        next: (data) => {
          this.userPhoto = data;
          this.#logger.info('NavbarTopComponent: ', 'User photo\n', data);
        },
        error: (error) => {
          this.#logger.error('NavbarTopComponent: ', 'User photo not found\n', error);
        },
      })
    } else {
      this.userPhoto = 'assets/images/photo_profile_default.png';
    }
  }

  private _isBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
}
